import React, { useEffect, useState } from 'react'
import axios from 'axios'
import { useLocalStorage } from '@rehooks/local-storage'
import { Link } from 'react-router-dom'
import Button from '@material-ui/core/Button'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import Typography from '@material-ui/core/Typography'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Switch from '@material-ui/core/Switch'

import { getBaseUrl } from '../store'
import { useEffectAfterMount } from '../../../hooks'

export default function CharacterList({ autoLoad = false }) {
    const [loginToken] = useLocalStorage('googleLogin')
    const [characters, setCharacters] = useState([])
    const [showOthers, setShowOthers] = useState(false)

    const loadCharacters = async () => {
        const params = {}
        if (!showOthers && loginToken) {
            params.email = loginToken.email
        }
        const {
            data: { characters },
        } = await axios.get((await getBaseUrl()) + '/characters', {
            params,
        })
        setCharacters(characters)
    }

    useEffect(() => {
        if (autoLoad) {
            loadCharacters()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [autoLoad])
    useEffectAfterMount(() => loadCharacters(), [showOthers])

    return !characters.length && !autoLoad ? (
        <Button onClick={loadCharacters}>Load Characters</Button>
    ) : (
        <div>
            <Typography variant="h4">Characters</Typography>
            {characters.map(({ id, name, email }) => (
                <Card key={id}>
                    <CardContent style={{ display: 'flex' }}>
                        <Typography variant="h5" style={{ flex: 1 }}>
                            {email !== loginToken?.email && `${email}: `}
                            {name}
                        </Typography>
                        <Link to={`/characters/${id}/skills`}>
                            <Button variant="contained">Load</Button>
                        </Link>
                    </CardContent>
                </Card>
            ))}
            <FormControlLabel
                control={
                    <Switch
                        checked={showOthers}
                        onChange={() => setShowOthers(on => !on)}
                        color="primary"
                    />
                }
                label="Show Other"
            />
        </div>
    )
}
