import React from 'react'
import axios from 'axios'

import races from '../../../data/races'

let baseUrl = ''
export async function getBaseUrl() {
    if (baseUrl) {
        return baseUrl
    }
    if (window.location.hostname !== 'spicemine.nebtown.info') {
        let guessUrl = `${window.location.protocol}//${window.location.hostname}:3001`
        try {
            await axios.get(guessUrl)
            return (baseUrl = guessUrl)
        } catch (e) {}
    }

    return (baseUrl = 'https://spicemine.nebtown.info/api')
}

const initialState = {
    name: '',
    race: '',
    stats: {
        might: 1,
        intellect: 1,
        psyche: 1,
        speed: 1,
        magic: 0
    },
    racialTalents: [],
    talents: [],
    skills: {},
    specializations: {},
    trappings: [],
}

const characterActions = (
    character,
    setCharacter,
    updateCharacter = updater =>
        setCharacter(character => ({ ...character, ...updater(character) }))
) => ({
    character,
    setCharacter,
    loadCharacter: async characterId => {
        const {
            data: { character },
        } = await axios.get((await getBaseUrl()) + '/characters/' + characterId)
        if (character) {
            setCharacter(character)
        }
        return character
    },
    saveCharacter: async (email) => {
        const characterId = `${email}_${character.name || character.race}`
        try {
            const {
                data: { status },
            } = await axios.put(
                (await getBaseUrl()) + '/characters/' + characterId,
                {
                    ...character,
                    id: characterId,
                }
            )
            return { status, characterId }
        } catch (e) {
            console.warn('Save failed', e)
            return { status: 'error' }
        }
    },
    selectRace: race =>
        setCharacter(character => ({
            ...character,
            race,
            talents: races.find(value => value.name === race).talents,
        })),
    setStat: (stat, value) =>
        updateCharacter(character => ({
            stats: { ...character.stats, [stat]: value },
        })),
    addTalent: talent =>
        setCharacter(character => ({
            ...character,
            talents: [...character.talents, talent],
        })),
    removeTalent: talent =>
        setCharacter(character => ({
            ...character,
            talents: character.talents.filter(value => value !== talent),
        })),
    setSkill: (skill, value, cost) =>
        setCharacter(character => ({
            ...character,
            skills: {
                ...character.skills,
                [skill]: {
                    value: parseInt(value),
                    cost: parseInt(cost),
                },
            },
        })),
    setSpecial: (specialization, value, cost) =>
        setCharacter(character => ({
            ...character,
            specializations: {
                ...character.specializations,
                [specialization]: {
                    value: parseInt(value),
                    cost: parseInt(cost),
                },
            },
        })),
})

const CharacterContext = React.createContext(characterActions({}, () => {}))
function useCharacter() {
    const context = React.useContext(CharacterContext)
    if (!context) {
        throw new Error(`useCharacter must be used within a CharacterProvider`)
    }
    return context
}
function CharacterProvider(props) {
    const [character, setCharacter] = React.useState(initialState)

    return (
        <CharacterContext.Provider
            value={characterActions(character, setCharacter)}
            {...props}
        />
    )
}
export { CharacterProvider, useCharacter }
