import React from 'react'
import {
    Switch,
    Route,
    matchPath,
    useLocation,
    useHistory,
    useRouteMatch,
    useParams,
} from 'react-router-dom'
import { useCharacter } from './store'

import Stepper from '@material-ui/core/Stepper'
import Step from '@material-ui/core/Step'
import StepLabel from '@material-ui/core/StepLabel'

import ChooseRace from './components/choose-race'
import ChooseStats from './components/choose-stats'
import ChooseTalents from './components/choose-talents'
import ChooseSkills from './components/choose-skills'
import ChooseTrappings from './components/choose-trappings'
import CharacterSheet from './components/CharacterSheet'

const steps = [
    ['race', 'Choose your Race'],
    ['stats', 'Pick your Stats'],
    ['talents', 'Select Talents'],
    ['skills', 'Train Skills'],
    ['trappings', 'Buy Trappings'],
    ['sheet', 'Sheet'],
]

export default function CharacterBuilder(p) {
    const { path } = useRouteMatch()
    const { characterId } = useParams()
    const history = useHistory()
    const location = useLocation()
    const { character, loadCharacter } = useCharacter()
    const activeStep = steps.findIndex(([page]) =>
        matchPath(location.pathname, { path: `${path}/${page}` })
    )
    React.useEffect(() => {
        if (
            characterId &&
            characterId !== 'new' &&
            character.id !== characterId
        ) {
            loadCharacter(characterId).then(success => {
                if (!success) {
                    console.warn(
                        "couldn't load character, todo return to character select"
                    )
                }
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [characterId, character.id])

    return (
        <div>
            <div>Global Chosen Race: {character.race}</div>
            <Stepper activeStep={activeStep} alternativeLabel>
                {steps.map(([page, label]) => (
                    <Step key={label} onClick={() => history.push(page)}>
                        <StepLabel>{label}</StepLabel>
                    </Step>
                ))}
            </Stepper>
            <div>
                <Switch>
                    <Route path={`${path}/race`}>
                        <ChooseRace />
                    </Route>
                    <Route path={`${path}/stats`}>
                        <ChooseStats />
                    </Route>
                    <Route path={`${path}/talents`}>
                        <ChooseTalents />
                    </Route>
                    <Route path={`${path}/skills`}>
                        <ChooseSkills />
                    </Route>
                    <Route path={`${path}/trappings`}>
                        <ChooseTrappings />
                    </Route>
                    <Route path={[path, `${path}/sheet`]}>
                        <CharacterSheet />
                    </Route>
                </Switch>
            </div>
        </div>
    )
}
