import React, {useState} from 'react'
import races from '../../../data/races'
import { useCharacter } from '../store'

import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Collapse from '@material-ui/core/Collapse';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { useHistory } from 'react-router-dom'

const useStyles = makeStyles({
    media: {
        height: 200,
    },
    flipcard: {
        'min-height': 400
    }
});

function RaceCard({race}) {
    const { selectRace } = useCharacter()
    const history = useHistory()
    const [expanded, setExpanded] = useState(false)

    const handleExpandedClick = () => {
        setExpanded(!expanded)
    }

    const classes = useStyles()
    return (
        <Grid item sm={6} lg={4}>
            <Card className="card large">
                <Collapse in={!expanded} timeout="auto" unmountOnExit>
                    <CardActionArea className={classes.flipcard}>
                        <CardMedia
                            className={classes.media}
                            image={`/img/${race.image}`}
                            title={race.display_name}
                        />
                        <CardContent>
                            <Typography gutterBottom component="h2">
                                {race.display_name}
                            </Typography>
                            <Typography>
                                {race.description}
                            </Typography>
                        </CardContent>
                    </CardActionArea>
                </Collapse>
                <Collapse in={expanded} timeout="auto" unmountOnExit>
                    <CardActionArea className={classes.flipcard}>
                        <CardContent>
                            <Typography gutterBottom component="h2">
                                {race.display_name}
                            </Typography>
                            <Typography>
                                {race.details}
                            </Typography>
                            {race.talents.length > 0 ? (
                                <div>
                                    <Typography>
                                        Talents:
                                    </Typography>
                                    <List component="list">
                                        {race.talents.map(talent => (
                                            <ListItem key={talent}>
                                                <ListItemText primary={talent}/>
                                            </ListItem>
                                        ))}
                                    </List>
                                </div>
                            ) : null}
                        </CardContent>
                    </CardActionArea>
                </Collapse>
                <CardActions>
                    <Button
                        size="small"
                        color="primary"
                        onClick={() => {
                            selectRace(race.name)
                            history.push('stats')
                        }}
                    >
                        Choose
                    </Button>
                    <Button
                        size="small"
                        color="primary"
                        onClick={handleExpandedClick}
                    >
                        More Info
                    </Button>
                </CardActions>
            </Card>
        </Grid>
    )
}

export default function ChooseRace () {
    return (
        <Grid container spacing={3}>
            {races.map(race => <RaceCard key={race.name} race={race}/>)}
        </Grid>
    )
}