import React from "react"

import { GoogleLogin, GoogleLogout } from "react-google-login"
import { useLocalStorage } from "@rehooks/local-storage"
import Avatar from '@material-ui/core/Avatar'

export function tokenIsValid(loginToken) {
    if (!loginToken) {
        return false
    }
    return loginToken.expires_at >= Date.now() / 1000
}

const oAuthClientId =
    "341262452680-svcd6b3uaqi5ij0ltbtrh539s55t174f.apps.googleusercontent.com"

export default function LoginButton({ render }) {
    const [loginToken, setLoginToken, delLoginToken] = useLocalStorage(
        "googleLogin"
    )
    return !tokenIsValid(loginToken) ? (
        <GoogleLogin
            clientId={oAuthClientId}
            buttonText="Login"
            onSuccess={async response => {
                console.debug("Google Login: Success", response)
                setLoginToken({
                    ...response.tokenObj,
                    ...response.profileObj,
                })
            }}
            onFailure={response => {
                console.warn("Google Login: Failure", response)
            }}
            render={renderProps =>
                render({ ...renderProps, buttonText: "Login" })
            }
        />
    ) : (
        <>
            <Avatar src={loginToken.imageUrl}/>
            <GoogleLogout
                clientId={oAuthClientId}
                buttonText="Logout"
                onLogoutSuccess={() => {
                    delLoginToken()
                }}
                render={renderProps =>
                    render({ ...renderProps, buttonText: "Logout" })
                }
            />
        </>
    )
}
