import React, {useState} from 'react'
import talents from '../../../data/talents'
import { useCharacter } from '../store'

import { makeStyles } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import Collapse from '@material-ui/core/Collapse'
import { useHistory } from 'react-router-dom'

const useStyles = makeStyles({
    talentBox: {
        minHeight: 50,
        border: '1px #000 solid',
        marginBottom: 10,
        padding: 5,
    }
})

const talentPointMax = 10

function TalentBox ({talentName, purchased=false, forced=false}) {
    const { character, addTalent, removeTalent } = useCharacter()
    const [expanded, setExpanded] = useState(false)
    const talent = talents.find(value => value.name === talentName) || {display_name: talentName}

    const talentPointsSpent = talents.filter(talent => character.talents.includes(talent.name)).reduce((acc, cur) => acc + cur.cost, 0)

    const handleAddTalent = () => {
        addTalent(talentName)
    }

    const handleRemoveTalent = () => {
        removeTalent(talentName)
    }

    const classes = useStyles()
    return (
        <Box className={classes.talentBox}>
            <Grid container>
                <Grid item sm={6}>
                    <Typography component="h2">
                        {talent.display_name}
                    </Typography>
                </Grid>
                <Grid item sm={3}>
                    {!forced &&
                        (purchased ? (
                            <Button onClick={handleRemoveTalent}>Remove</Button>
                        ) : (
                            <Button
                                disabled={
                                    !(
                                        talent.cost <=
                                        talentPointMax - talentPointsSpent
                                    )
                                }
                                onClick={handleAddTalent}
                            >
                                Add
                            </Button>
                        ))}
                </Grid>
                <Grid item sm={3}>
                    <Button
                        onClick={() => setExpanded(!expanded)}
                    >More Info</Button>
                </Grid>
            </Grid>
            <Collapse in={expanded} timeout="auto" unmountOnExit>
                {talent.description}
                {talent.bundled && talent.bundled.length && (
                    <div className="description-bundled">
                        Includes:
                        {talent.bundled.map(bundledName => (
                            <div key={bundledName}>- {bundledName}</div>
                        ))}
                    </div>
                )}
            </Collapse>
        </Box>
    )
}

export default function ChooseTalents () {
    const { character } = useCharacter()
    const history = useHistory()
    const characterTalents = talents
        .filter(talent => character.talents.includes(talent.name))
    const talentPointsSpent = characterTalents.reduce((acc, cur) => acc + cur.cost, 0)

    const bundledTalentNames = characterTalents
        .map(({ bundled }) => bundled)
        .flat()
        .filter(Boolean)
    const purchasableTalents = talents.filter(talent =>
        talent.purchasable &&
        !character.racialTalents.includes(talent.name) &&
        !character.talents.includes(talent.name) &&
        !bundledTalentNames.includes(talent.name)
    )

    return (
        <Grid container spacing={2}>
            <Grid item sm={12}>
                <Typography component='h2'>{`Talent Points ${talentPointsSpent}/${talentPointMax}`}</Typography>
            </Grid>
            <Grid item sm={6}>
                {character.racialTalents.map(talent => (
                    <TalentBox
                        key={talent}
                        talentName={talent}
                        purchased={true}
                        forced={true}
                    />
                ))}
                {character.talents.map(talent => (
                    <TalentBox
                        key={talent}
                        talentName={talent}
                        purchased={true}
                    />
                ))}
                {bundledTalentNames.map(name => (
                    <TalentBox key={name} talentName={name} purchased forced />
                ))}
            </Grid>
            <Grid item sm={6}>
                {purchasableTalents.map(talent => (
                    <TalentBox
                        key={talent.name}
                        talentName={talent.name}
                    />
                ))}
            </Grid>
            <Grid item sm={12}>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={() => history.push('skills')}
                    disabled={talentPointsSpent < talentPointMax}
                >
                    Confirm
                </Button>
            </Grid>
        </Grid>
    )
}