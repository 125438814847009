import React from 'react'

import { makeStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import IconButton from '@material-ui/core/IconButton'
import MenuIcon from '@material-ui/icons/Menu'
import Typography from '@material-ui/core/Typography'
import CharacterBuilder from './pages/character-builder/character-builder'
import LoginButton from './pages/character-builder/components/LoginButton'
import CharacterList from './pages/character-builder/components/CharacterList'
import { CharacterProvider } from './pages/character-builder/store'
import DocumentsViewer from './pages/documents/components/DocumentsViewer'
import {
    BrowserRouter as Router,
    Link,
    Route,
    Redirect,
} from 'react-router-dom'
import './App.css'

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    title: {
        marginRight: theme.spacing(2),
    },
    flexGrow: {
        flexGrow: 1,
    },
}))

function App() {
    const classes = useStyles()
    const renderedApp = (
        <div className="App">
            <AppBar position="static">
                <Toolbar>
                    <IconButton
                        edge="start"
                        className={classes.menuButton}
                        color="inherit"
                        aria-label="menu"
                    >
                        <MenuIcon />
                    </IconButton>
                    <Link to="/characters">
                        <Typography variant="h5" className={classes.title}>
                            Spice-Mine
                        </Typography>
                    </Link>
                    <Link to="/characters">
                        <Typography variant="h6" className={classes.title}>
                            Characters
                        </Typography>
                    </Link>
                    <Link to="/rules/">
                        <Typography variant="h6" className={classes.title}>
                            Rules
                        </Typography>
                    </Link>
                    <span className={classes.flexGrow} />
                    <LoginButton
                        render={renderProps => (
                            <Button
                                color="inherit"
                                onClick={renderProps.onClick}
                                disabled={renderProps.disabled}
                            >
                                {renderProps.buttonText}
                            </Button>
                        )}
                    />
                </Toolbar>
            </AppBar>
            <div className="content">
                <Route path="/" exact>
                    <Redirect to="/characters" />
                </Route>
                <Route path={`/characters`} exact>
                    <div style={{ display: 'flex' }}>
                        <div style={{ flex: 1 }} />
                        <Link
                            to="/characters/new/race"
                            style={{ marginLeft: 'auto' }}
                        >
                            <Button variant="contained" color="primary">
                                New Character
                            </Button>
                        </Link>
                    </div>
                    <CharacterList autoLoad />
                </Route>
                <Route path={`/characters/:characterId`}>
                    <CharacterProvider>
                        <CharacterBuilder />
                    </CharacterProvider>
                </Route>
                <Route path="/rules" exact>
                    <Redirect to="/rules/" />
                </Route>
                <Route path={['/rules/:documentId', '/rules/']}>
                    <DocumentsViewer />
                </Route>
            </div>
        </div>
    )
    return <Router>{renderedApp}</Router>
}

export default App
