import React from 'react'
import ReactMarkdown from 'react-markdown'
import remarkGfm from 'remark-gfm'
import useAxios from 'axios-hooks'
import { Link, useParams } from 'react-router-dom'

import './Documents.scss'
import { Container } from '@material-ui/core'

export default function DocumentsViewer() {
    const { documentId } = useParams()

    const [{ data: source, loading }] = useAxios(
        `/documents/${documentId || 'index.md'}`
    )

    return (
        <Container maxWidth="md" className="document-viewer">
            {!loading && (
                <ReactMarkdown
                    children={source}
                    remarkPlugins={[remarkGfm]}
                    components={{
                        a: ({ href, ...props }) =>
                            href.includes('://') ? (
                                // eslint-disable-next-line
                                <a href={href} target="_blank" {...props} />
                            ) : href.substring(0, 1) === '#' ? (
                                <a href={href} {...props} />
                            ) : (
                                <Link to={href} {...props} />
                            ),
                        h2: ({ children, ...props }) => {
                            const text = children.join(' ')
                            const slug = text
                                .toLowerCase()
                                .replace(/^(section \d+: )/, '')
                                .replace(/\s+/g, '-')
                                .replace(/[^a-z-]/g, '')

                            return React.createElement(
                                `h${props.level}`,
                                { id: slug },
                                children
                            )
                        },
                    }}
                />
            )}
        </Container>
    )
}
