import React from 'react'
import skills from '../../../data/skills'
import specializations from '../../../data/specializations'
import { useCharacter } from '../store'

import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import RadioButtonCheckedIcon from '@material-ui/icons/RadioButtonChecked';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import Collapse from '@material-ui/core/Collapse'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import Divider from '@material-ui/core/Divider';

const skillBuyMax = 3
const totalSkillPoints = 100
const specializationBuyMax = 5

const SpecializationBlock = ({specialization, parentPool}) => {
    const { character, setSpecial } = useCharacter()
    const skillPointsSpent = (
        Object.values(character.skills).reduce((acc, cur) => acc + cur.cost, 0) +
        Object.values(character.specializations).reduce((acc, cur) => acc + cur.cost, 0)
    )
    const skillPointsLeft = totalSkillPoints - skillPointsSpent
    const specialValue = character.specializations[specialization.name]
        ? character.specializations[specialization.name].value
        : 0

    const totalPool = parentPool + specialValue

    const handleSpecialUp = (value, cost) => {
        if (specialValue >= specializationBuyMax) {
            return
        }
        setSpecial(specialization.name, value, cost)
    }

    const handleSpecialDown =(value, cost) => {
        if (specialValue <= 0) {
            return
        }
        if (value === specialValue) {
            return setSpecial(specialization.name, 0, 0)
        }
        setSpecial(specialization.name, value, cost)
    }

    const pips = () => {

        const elements = []
        const costs = []

        for(let i = 0; i < specializationBuyMax; i++) {
            const value = i + 1
            costs.push((value + 3))
            const newCost = costs.reduce((acc, cur) => acc + cur, 0)
            const element = value <= specialValue
                ? (
                    <IconButton
                        onClick={() => handleSpecialDown(value, newCost)}
                        key={`${specialization.name}_pip_${i}`}
                    >
                        <RadioButtonCheckedIcon/>
                    </IconButton>
                )
                : (
                    <IconButton
                        onClick={() => handleSpecialUp(value, newCost)}
                        key={`${specialization.name}_pip_${i}`}
                        disabled={newCost > skillPointsLeft}
                    >
                        <RadioButtonUncheckedIcon/>
                    </IconButton>
                )

            elements.push(element)
        }

        return (
            <span>
                {elements.map(element => (
                    element
                ))}
            </span>
        )

    }

    return (
        <ListItem>
            <ListItemText>
                {specialization.display_name}: {totalPool}
            </ListItemText>
            {pips()}
        </ListItem>
    )
}

const SkillBlock = ({skill}) => {
    const { character, setSkill } = useCharacter()
    const statValue = character.stats[skill.governing_stat] || 0
    const skillValue = character.skills[skill.name] ? character.skills[skill.name].value : 0
    const skillPointsSpent = (
        Object.values(character.skills).reduce((acc, cur) => acc + cur.cost, 0) +
        Object.values(character.specializations).reduce((acc, cur) => acc + cur.cost, 0)
    )
    const skillPointsLeft = totalSkillPoints - skillPointsSpent
    const childSpecializations = specializations.filter(special => special.governing_skill === skill.name)

    const totalPool = statValue + skillValue

    const handleSkillUp = (value, cost) => {
        if (skillValue >= skillBuyMax) {
            return
        }
        setSkill(skill.name, value, cost)
    }

    const handleSkillDown = (value, cost) => {
        if (skillValue <= 0) {
            return
        }
        if (value === skillValue) {
            return setSkill(skill.name, 0, 0)
        }
        setSkill(skill.name, value, cost)
    }

    const pips = () => {

        const elements = []
        let cost = 0

        for(let i = 0; i < skillBuyMax; i++) {
            const value = i + 1
            const newCost = cost + value
            const element = value <= skillValue
                ? (
                    <IconButton
                        onClick={() => handleSkillDown(value, newCost)}
                        key={`${skill.name}_pip_${i}`}
                    >
                        <RadioButtonCheckedIcon/>
                    </IconButton>
                )
                : (
                    <IconButton
                        onClick={() => handleSkillUp(value, newCost)}
                        key={`${skill.name}_pip_${i}`}
                        disabled={value > skillPointsLeft}
                    >
                        <RadioButtonUncheckedIcon/>
                    </IconButton>
                )

            elements.push(element)
            cost += value
        }

        return (
            <span>
                {elements.map(element => (
                    element
                ))}
            </span>
        )
    }

    return (
        <Grid item sm={12} md={6} lg={4}>
            <Card>
                <CardContent>
                    <List>
                        <ListItem>
                            <ListItemText>{skill.display_name}: {totalPool}</ListItemText>
                            {pips()}
                        </ListItem>
                    </List>
                    <Collapse in={skillValue === skillBuyMax} timeout="auto" unmountOnExit>
                        <Divider/>
                        <List>
                            {
                                childSpecializations.map(special => (
                                    <SpecializationBlock
                                        key={special.name}
                                        specialization={special}
                                        parentPool={totalPool}
                                    />
                                ))
                            }
                        </List>
                    </Collapse>
                </CardContent>
            </Card>
        </Grid>
    )
}

export default function ChooseSkills () {
    const { character } = useCharacter()

    const skillPointsSpent = (
        Object.values(character.skills).reduce((acc, cur) => acc + cur.cost, 0) +
        Object.values(character.specializations).reduce((acc, cur) => acc + cur.cost, 0)
    )

    return (
        <Grid container spacing={4}>
            <Grid item sm={12}>
                {skillPointsSpent}/{totalSkillPoints}
            </Grid>
            {skills.map(skill => (
                <SkillBlock key={skill.name} skill={skill}/>
            ))}
        </Grid>
    )
}