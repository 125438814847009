import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { useCharacter } from '../store'
import { useHistory } from 'react-router-dom'
import stats from '../../../data/stats'

import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles(theme => ({
    center: {
        margin: '0 auto',
        display: 'block'
    }
}))

function StatGroup ({stat}) {
    const { character, setStat } = useCharacter()
    const value = character.stats[stat.name]
    const minValue = stat.min
    const maxValue = stat.max
    const classes = useStyles()

    const totalPoints = Object.entries(character.stats).map(value => value[1]).reduce((acc, cur) => acc + cur)

    const handleDecreaseStat = () => {
        setStat(stat.name, value - 1)
    }

    const handleIncreaseStat = () => {
        setStat(stat.name, value + 1)
    }

    return (
        <Grid item sm={6} lg={3}>
            <Card>
                <CardContent>
                    <Grid container spacing={3}>
                        <Grid item sm={12}>
                            <Typography align="center">
                                {stat.displayName}
                            </Typography>
                        </Grid>
                        <Grid item sm={4}>
                            <Button
                                variant="outlined"
                                color="primary"
                                className={classes.center}
                                onClick={handleDecreaseStat}
                                disabled={value <= minValue}
                            >
                                {`-1`}
                            </Button>
                        </Grid>
                        <Grid item sm={4}>
                            <Typography align="center">
                                {value}
                            </Typography>
                        </Grid>
                        <Grid item sm={4}>
                            <Button
                                variant="outlined"
                                color="primary"
                                className={classes.center}
                                onClick={handleIncreaseStat}
                                disabled={totalPoints >= 15 || value === maxValue}
                            >
                                {`+1`}
                            </Button>
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
        </Grid>
    )
}

export default function ChooseStats () {
    const history = useHistory()
    const primaryStats = stats.filter(stat => !stat.parentStat)

    return (
        <Grid container spacing={4}>
            {primaryStats.map(stat => <StatGroup key={stat.name} stat={stat}/>)}
            <Grid item sm={12}>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={() => history.push('talents')}
                >
                    Confirm
                </Button>
            </Grid>
        </Grid>
    )
}