import React, { useRef, useState } from 'react'

import Button from '@material-ui/core/Button'
import ButtonGroup from '@material-ui/core/ButtonGroup'
import TextField from '@material-ui/core/TextField'
import { useCharacter } from '../store'
import { useLocalStorage } from '@rehooks/local-storage'
import { useHistory } from 'react-router-dom'

import CharacterList from './CharacterList'

export default function CharacterSheet() {
    const { character, setCharacter, saveCharacter } = useCharacter()
    const history = useHistory()
    const [saveStatus, setSaveStatus] = useState('')
    const [loginToken] = useLocalStorage('googleLogin')
    return (
        <div>
            <div>
                <TextField
                    label="Character Name"
                    margin="normal"
                    variant="outlined"
                    value={character.name}
                    onChange={(event) => {
                        console.log('val', event.target.value, 'and', character)
                        setCharacter((character) => ({
                            ...character,
                            name: event.target.value,
                        }))
                    }}
                />
            </div>
            <ImportExportButtons />
            <div>
                <Button
                    onClick={async () => {
                        const { status, characterId } = await saveCharacter(
                            loginToken.email
                        )
                        setSaveStatus(status === 'ok' ? 'success' : 'error')
                        if (status === 'ok') {
                            history.push(`/characters/${characterId}/sheet`)
                        }
                    }}
                    disabled={
                        !loginToken ||
                        !loginToken.email ||
                        saveStatus === 'success'
                    }
                >
                    {{ success: 'Success', error: 'Save Failed' }[saveStatus] ||
                        'Save'}
                </Button>
                <CharacterList />
            </div>
        </div>
    )
}

function ImportExportButtons() {
    const { character, setCharacter } = useCharacter()
    const fileInputRef = useRef()

    const exportToJsonFile = async () => {
        const fileName = `${character.name}-${character.race}`
        const data = JSON.stringify(character)
        const blob = new Blob([data], { type: 'application/json' })
        const href = await URL.createObjectURL(blob)
        const link = document.createElement('a')
        link.href = href
        link.download = fileName + '.json'
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
    }
    return (
        <ButtonGroup variant="contained">
            <Button onClick={exportToJsonFile}>Export</Button>
            <Button onClick={() => fileInputRef.current.click()}>
                Import
                <input
                    type="file"
                    accept=".json"
                    ref={fileInputRef}
                    onChange={async (e) => {
                        const file = e.target.files[0]
                        const fileReader = new FileReader()
                        fileReader.onloadend = () => {
                            setCharacter(JSON.parse(fileReader.result))
                        }
                        fileReader.readAsText(file)
                    }}
                    style={{ display: 'none' }}
                />
            </Button>
        </ButtonGroup>
    )
}
