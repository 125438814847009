import React, {useState} from 'react'

export default function ChooseTrappings () {
    const [step, setStep] = useState('race')

    return (
        <div>
            <span onClick={() => setStep((val) => val + 1)}>Current Step: {step}</span>
        </div>
    )
}